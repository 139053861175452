const iframes = {
  elements: {
    iframes: Array.from(document.querySelectorAll('.curtis-text iframe')) as HTMLIFrameElement[],
    videos: [] as HTMLIFrameElement[]
  },

  init() {
    this.elements.videos = Array.from(this.elements.iframes).filter(iframe => {
      const src = iframe.src.toLowerCase();
      return src.includes('youtube.com') ||
             src.includes('vimeo.com') ||
             src.includes('dailymotion.com') ||
            src.includes('video');
    });

    this.bindEvents();
  },

  bindEvents() {
    if(!this.elements.videos.length) return;

    this.elements.videos.forEach((iframe) => this.wrap(iframe));
  },

  wrap(element: Element) {
    if(!element || !element.parentNode) return;

    const wrapper = document.createElement('div');
    wrapper.classList.add('iframe-wrapper');
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  }
};

export default iframes;
