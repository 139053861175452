import Splide from '@splidejs/splide';

type Elements = {
  sliders: HTMLElement[];
  videos: HTMLVideoElement[];
};

type Hero = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  initializeVideoPlayers: () => void;
  initializeSliders: () => void;
  setupCustomNavigation: (slider: HTMLElement) => void;
};

const hero: Hero = {
  elements: {
    sliders: [],
    videos: []
  },

  init() {
    this.elements.videos = Array.from(document.querySelectorAll<HTMLVideoElement>('.module__hero video'));
    this.elements.sliders = Array.from(document.querySelectorAll<HTMLElement>('.module__hero .splide'));
    this.bindEvents();
  },

  bindEvents() {
    if (this.elements.videos.length > 0) {
      this.initializeVideoPlayers();
    }

    if (this.elements.sliders.length > 0) {
      this.initializeSliders();
    }
  },

  initializeVideoPlayers() {
    this.elements.videos.forEach(video => {
      const wrapper = video.parentElement;
      const button = wrapper?.querySelector('button');

      // Function to handle video ready state (metadata loaded)
      const handleVideoReady = () => {
        wrapper?.classList.add('is-ready');
      };

      // Check if video is already loaded
      if (video.readyState >= 4) { // HAVE_ENOUGH_DATA
        handleVideoReady();
      } else if (video.readyState >= 2) { // HAVE_CURRENT_DATA
        handleVideoReady();
      } else {
        // If not loaded, wait for both events
        video.addEventListener('loadedmetadata', handleVideoReady);
      }

      video.addEventListener('click', () => {
        if (wrapper?.classList.contains('is-playing')) {
          video.pause();
          wrapper?.classList.remove('is-playing');
        }
      });

      video.addEventListener('playing', () => {
        if(video.dataset.controls === 'playing') {
          video.controls = true;
        }
      });

      if (button) {
        button.addEventListener('click', () => {
          if (video.paused) {
            video.play();
            wrapper?.classList.add('is-playing');
          } else {
            video.pause();
            wrapper?.classList.remove('is-playing');
          }
        });
      }
    });
  },

  initializeSliders() {
    this.elements.sliders.forEach(slider => {
      this.setupCustomNavigation(slider);

      const splide = new Splide(slider, {
        type: 'loop',
        perPage: 1,
        autoplay: true,
        interval: 7000,
        arrows: true,
        pagination: false,
      });

      splide.on('mounted', () => {
        if (splide.length === 1) {
          splide.options = { ...splide.options, drag: false };
        }
      });

      splide.mount();
    });
  },

  setupCustomNavigation(slider: HTMLElement) {
    const buttons: HTMLButtonElement[] = Array.from(slider.querySelectorAll('.module__slide--button button'));

    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const isPrevious = button.dataset.action === 'previous';
        const selector = isPrevious ? '.splide__arrow--prev' : '.splide__arrow--next';
        const splideButton: HTMLButtonElement | null = slider.querySelector(selector);

        if (splideButton) {
          splideButton.click();
        }
      });
    });
  },
};

export default hero;
