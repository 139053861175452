// Add interface for AJAX response
interface WorksResponse {
  results?: string;
  total?: number;
}

type Elements = {
  module: HTMLElement | null;
  intro: HTMLElement | null;
  form: HTMLFormElement | null;
  toggle: HTMLButtonElement | null;
  resultsList: HTMLUListElement | null;
};

type Inputs = {
  keyword: HTMLInputElement | null;
  composer: HTMLSelectElement | null;
  instrument: HTMLSelectElement | null;
  year: HTMLSelectElement | null;
  centennial: HTMLInputElement | null;
};

type FilterValues = {
  keyword: string | null;
  composer: string | null;
  instrument: string | null;
  year: string | null;
  centennial: boolean;
};

type URLParams = {
  keyword?: string;
  composer?: string;
  instrument?: string;
  year?: string;
  centennial?: 'true' | 'false';
};

type InputKey = keyof Inputs;

type ParamToInputMap = {
  [K in keyof URLParams]: InputKey;
};

const paramToInputMap: ParamToInputMap = {
  keyword: 'keyword',
  composer: 'composer',
  instrument: 'instrument',
  year: 'year',
  centennial: 'centennial'
};

interface WorksSearch {
  postsPerPage: number;
  elements: Elements;
  inputs: Inputs;
  init(): void;
  setInputs(): void;
  bindEvents(): void;
  getValues(): FilterValues;
  updateURLParameters(values: FilterValues): void;
  setInitialStateFromURL(): void;
  handleFormSubmit(e: SubmitEvent): void;
  handleToggleClick(): void;
  getResults(): void;
}

const worksSearch: WorksSearch = {
  postsPerPage: 48,

  elements: {
    module: document.querySelector('.module--works'),
    intro: document.querySelector('.module--works .module__intro'),
    form: document.querySelector('.module--works form'),
    toggle: document.querySelector('.module--works button[data-action="works-filter-toggle"]'),
    resultsList: document.querySelector('.module--works .module__list > .container')
  },

  inputs: {
    keyword: null,
    composer: null,
    instrument: null,
    year: null,
    centennial: null,
  },

  init() {
    if (!this.elements.module) {
      return;
    }

    this.setInputs();
    this.setInitialStateFromURL();
    this.bindEvents();
    this.getResults();
  },

  setInputs() {
    if(!this.elements.form) {
      return;
    }

    this.inputs.keyword = this.elements.form.querySelector('input[name="filter-keyword"]');
    this.inputs.composer = this.elements.form.querySelector('select[name="filter-composer"]');
    this.inputs.instrument = this.elements.form.querySelector('select[name="filter-instrument"]');
    this.inputs.year = this.elements.form.querySelector('select[name="filter-year"]');
    this.inputs.centennial = this.elements.form.querySelector('input[name="filter-centennial"]');
  },

  bindEvents() {
    this.elements.form?.addEventListener('submit', (e) => this.handleFormSubmit(e));
    this.elements.toggle?.addEventListener('click', () => this.handleToggleClick());

    Object.values(this.inputs).forEach(input => {
      if (input) {
        if (input instanceof HTMLSelectElement ||
            (input instanceof HTMLInputElement && input.type === 'checkbox')) {
          input.addEventListener('change', () => this.getResults());
        }
      }
    });

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      if (this.elements.module) {
        this.getResults();
      }
    });
  },

  getValues(): FilterValues {
    return {
      keyword: this.inputs.keyword?.value || null,
      composer: this.inputs.composer?.value || null,
      instrument: this.inputs.instrument?.value || null,
      year: this.inputs.year?.value || null,
      centennial: Boolean(this.inputs.centennial?.checked),
    };
  },

  updateURLParameters(values: FilterValues) {
    const searchParams = new URLSearchParams(window.location.search);

    Object.entries(values).forEach(([ key, value ]) => {
      if (key === 'centennial') {
        if (value === true) {
          searchParams.set(key, 'true');
        } else {
          searchParams.delete(key);
        }
      } else if (value !== null && value !== 'all') {
        searchParams.set(key, String(value));
      } else {
        searchParams.delete(key);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Partial<URLParams> = {};

    searchParams.forEach((value, key) => {
      if (key in paramToInputMap) {
        if (key === 'centennial') {
          if (value === 'true' || value === 'false') {
            params[key] = value;
          }
        } else {
          params[key as Exclude<keyof URLParams, 'centennial'>] = value;
        }
      }
    });

    Object.entries(params).forEach(([ key, value ]) => {
      const inputKey = paramToInputMap[key as keyof URLParams];
      if (inputKey && value) {
        const input = this.inputs[inputKey];
        if (input) {
          if (input instanceof HTMLInputElement && input.type === 'checkbox') {
            input.checked = value === 'true';
          } else if (input instanceof HTMLSelectElement || input instanceof HTMLInputElement) {
            input.value = value;
          }
        }
      }
    });
  },

  handleFormSubmit(e: SubmitEvent) {
    e.preventDefault();
    this.getResults();
  },

  handleToggleClick() {
    this.elements.module?.classList.toggle('filter-open');
  },

  getResults() {
    const values = this.getValues();
    this.elements.module?.classList.add('loading');
    this.updateURLParameters(values);

    const backendData = {
      action: 'action_get_works',
      selected: {
        ...values,
        centennial: values.centennial ? 'true' : 'false'
      },
    };

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: backendData,
    })
    .done((success: string) => {
      let data: WorksResponse;
      try {
        data = JSON.parse(success);
      } catch (e) {
        console.error('Failed to parse JSON response:', e);
        return;
      }

      if(this.elements.resultsList && data.results) {
        this.elements.resultsList.innerHTML = data.results;
      } else if(this.elements.resultsList) {
        this.elements.resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => {
      console.error('AJAX request failed:', errorThrown);
    })
    .always(() => this.elements.module?.classList.remove('loading'));
  },
};

export default worksSearch;
